@import "~@/erp/styles/variables/variables.scss";

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































.client-msg {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  // 左侧
  .client-left {
    border: 1px solid #ededed;
    padding: 25px 15px;
    height: 100vh;
    // top
    .client-left-first {
      > .el-row + .el-row {
        margin-top: 15px;
      }
      .split-50-row {
        > .el-row {
          width: 50%;
        }
      }
    }
    // middle
    .client-left-second {
      border-top: 1px solid #ededed;
      margin-top: 20px;
      padding-top: 20px;
      > .el-row {
        text-align: center;
        width: 50%;
        span {
          font-size: 17px;
          color: #0486fe;
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
        p {
          margin-top: 8px;
        }
        .el-button {
          margin-top: 15px;
        }
      }
      > .el-row + .el-row {
        > .el-row {
          border-left: 1px solid #ededed;
        }
      }
    }
    // bottom
    .client-left-three {
      border-top: 1px solid #ededed;
      margin-top: 20px;
      padding-top: 20px;
      > .el-row {
        p {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          word-wrap: break-word;
        }
      }
      > .el-row + .el-row {
        margin-top: 20px;
      }
    }
  }
  //右侧
  .clent-right {
    border: 1px solid #ededed;
    padding: 25px 15px;
    height: 100vh;
    .floor {
      .p-title {
        margin-bottom: 15px;
      }
    }
    .floor + .floor {
      margin-top: 30px;
    }
    .roll-box {
      height: 223px;
      .el-table {
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 10px;
      }
    }
  }
}
.follows-table {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.u160 {
  color: #8d8f92;
}
.no-text {
  font-weight: normal !important;
  color: #909399 !important;
  font-size: 14px !important;
}
.no-assign {
  font-weight: normal !important;
  color: #909399 !important;
  text-decoration: none !important;
}
.person-span {
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 邮箱链接
.prod-code {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
.h6-tooltip {
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
