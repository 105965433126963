@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.customer-info {
  height: calc(100% - 550px);
  overflow-y: auto;
  position: relative;
  .operate {
    position: fixed;
    bottom: 20px;
    height: 50px;
    line-height: 50px;
    width: 81%;
    text-align: center;
    background: #fff;
    z-index: 999;
    border-top: 1px solid #ededed;
  }
}
.join-table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    height: 60px;
    border: 1px solid #f5f7fa;
  }
  th {
    height: 38px;
    line-height: 38px !important;
    font-weight: normal;
  }
  td:nth-child(even) {
    font-family: 'PingFang Bold';
    max-width: 300px;
    overflow: hidden;
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  td:nth-child(odd) {
    width: 100px;
    text-align: center;
  }
}
.no-text {
  font-weight: normal;
  color: #909399;
}
.text-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.email-skip {
  color: #409eff;
  text-decoration: underline;
  cursor: pointer;
}
::v-deep {
  .join-table {
    .el-form-item--small.el-form-item {
      margin-bottom: 0 !important;
    }
    .el-input__inner {
      height: 33px !important;
    }
    .el-form-item__error {
      font-weight: normal !important;
    }
  }
  .logMgCls {
    .el-select__tags {
      > span {
        display: flex;
      }
    }
  }
  .el-select__tags-text {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  // .el-select .el-tag__close.el-icon-close {
  //   top: -5px;
  // }
}
