@import "~@/erp/styles/variables/variables.scss";


























































































































































































.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    padding-left: 30px !important;
    border-radius: 2px;
    h4 {
      font-size: 30px;
    }
    p {
      margin-top: 5px;
    }
  }
  // > .el-col:nth-child(2) {
  //   h4 {
  //     color: green;
  //   }
  // }
  // > .el-col:last-child {
  //   h4 {
  //     color: red;
  //   }
  // }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
