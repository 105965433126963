@import "~@/erp/styles/variables/variables.scss";



























































































































































.dialog-content {
  padding: 0 15px;
  overflow: hidden;
  .el-row {
    span {
      color: #666;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      color: #000;
    }
  }
  .floor {
    border-top: 1px solid #ededed;
    padding-top: 20px;
  }
}
