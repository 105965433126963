@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































.address {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.link {
  color: #46a6ff;
  cursor: pointer;
  text-decoration: underline;
}
