@import "~@/erp/styles/variables/variables.scss";




















































































































.audit-progress {
  .el_timeline {
    .floor {
      .floor-content-row + .floor-content-row {
        margin-left: 20px;
      }
    }
  }
}

.el_timeline {
  width: 90%;
  margin: 0 auto;
}
::v-deep {
  .el_timeline {
    .el-timeline-item__wrapper {
      .el-timeline-item__timestamp.is-top {
        font-size: 14px;
        color: #000 !important;
      }
    }
    .el-timeline-item__node--normal {
      left: -3px;
      width: 15px;
      height: 16px;
    }
  }
}
