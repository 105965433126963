@import "~@/erp/styles/variables/variables.scss";




































































































































































































































































































.dialog-content {
  padding: 0 15px;
  max-height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  .el-row {
    span {
      color: #666;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      color: #000;
    }
  }
  .floor {
    border-top: 1px solid #ededed;
    padding-top: 20px;
  }
  .pic-box {
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff !important;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
}
.blue-text {
  text-decoration: underline;
  color: #1890ff !important;
}
