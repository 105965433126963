@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































.public-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  .right-fixed {
    position: absolute;
    top: 6.5px;
    right: 50px;
  }
  .tabs-content {
    padding: 0 30px;
    height: calc(100% - 55px);
  }
}
::v-deep {
  .el-tabs--border-card {
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
  }
  .el-tabs {
    height: 100%;
  }
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs__content {
    height: calc(100% - 39px);
    overflow-y: auto;
  }
  .client-mail .el-tabs__content {
    padding: 0;
  }
}
